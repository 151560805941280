//////////////////////// DEPENDENCIES ////////////////////////

import React from 'react';

import DriverTracker from '../../reusable/DriverTracker';

//////////////////////// COMPONENT ////////////////////////

export default function MoveTracking(props) {
  const { move } = props;

  return (
    <>
      {/* Render map when the move is in progress */}
      {!move.cancel_status && move.status && (move.status.includes(`pickup`) || move.status.includes(`delivery`)) ? (
        <DriverTracker move={move} />
      ) : null}
    </>
  );
}
