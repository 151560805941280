import React from 'react';
import moment from 'moment';
import { getPropValue } from '@hopdrive/sdk/lib/modules/utilities';
import { makeStyles, Grid, Typography, Tooltip } from '@material-ui/core';

const placeholder = require('../../static/vehicle-placeholder.jpg');

/** Capitalize the first letter of the first word */
const capFirst = (str = null) => {
  if (str && typeof str === `string`) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};

/** Get formatted vehicle string from the move object */
const getFormattedVehicleFromMove = (move = null, includeYear = true, includeColor = true) => {
  // Default fallback vehicle string when not enough info is provided
  const fallbackVehicle = `No Vehicle Info`;

  // Check for move
  if (move) {
    // Set local variables
    const year = move.vehicle_year || move.driver_vehicle_year || null;
    const make = move.vehicle_make || move.driver_vehicle_make || null;
    const model = move.vehicle_model || move.driver_vehicle_model || null;
    const color = move.vehicle_color || move.driver_vehicle_color || null;

    // Set local vehicle string
    let vehicle = ``;

    // Check for make and model
    if (make && model) vehicle = `${make} ${model}`;
    else if (make) vehicle = make;
    else if (model) vehicle = model;
    else vehicle = fallbackVehicle;

    // Include color and year if specified
    if (includeYear && year) vehicle = `${year} ${vehicle}`;
    if (includeColor && color) vehicle = `${vehicle} (${capFirst(color)})`;

    // Return the built vehicle string
    return vehicle;
  }
  return fallbackVehicle;
};

/** Get a cleansed phone number from a string */
const getCleansedPhoneNumber = (str = null, removeCountry = false) => {
  if (str) {
    let cleaned = ('' + str).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      let intlCode = match[1] ? '+1 ' : '';
      return [removeCountry ? `` : intlCode, '(', match[2], ')\xa0', match[3], '-\u2060', match[4]].join('');
    }
  }
  return str;
};

/** Get driver name from a move/driver/user object */
const getDriverName = (obj = null) => {
  if (obj) {
    if (getPropValue(obj, `name`)) return obj.name;
    if (getPropValue(obj, `driver_name`)) return obj.driver_name;
    if (getPropValue(obj, `display_name`)) return obj.display_name;
    if (getPropValue(obj, `driver.name`)) return obj.driver.name;
    if (getPropValue(obj, `driver.driver_name`)) return obj.driver.driver_name;
    if (getPropValue(obj, `driver.display_name`)) return obj.driver.display_name;
    if (getPropValue(obj, `user.name`)) return obj.user.name;
    if (getPropValue(obj, `user.driver_name`)) return obj.user.driver_name;
    if (getPropValue(obj, `user.display_name`)) return obj.user.display_name;
    if (getPropValue(obj, `driver.user.name`)) return obj.driver.user.name;
    if (getPropValue(obj, `driver.user.driver_name`)) return obj.driver.user.driver_name;
    if (getPropValue(obj, `driver.user.display_name`)) return obj.driver.user.display_name;
  }
  return null;
};

/** Get driver avatar from a move/driver/user object */
const getDriverAvatar = (obj = null) => {
  if (obj) {
    if (getPropValue(obj, `avatar_url`)) return obj.avatar_url;
    if (getPropValue(obj, `driver.avatar_url`)) return obj.driver.avatar_url;
    if (getPropValue(obj, `user.avatar_url`)) return obj.user.avatar_url;
    if (getPropValue(obj, `driver.user.avatar_url`)) return obj.driver.user.avatar_url;
  }
  return null;
};

/** Get the driver's first name from the display_name */
const getDriverFirstNameFromDisplayName = (displayName = null) => {
  if (displayName) {
    const splitArr = displayName.split(` `);
    if (splitArr.length) {
      const firstName = splitArr[0];
      return firstName;
    }
  }
  return null;
};

/** Get the driver's middle name from the display_name */
const getDriverMiddleNameFromDisplayName = (displayName = null) => {
  if (displayName) {
    const splitArr = displayName.split(` `);
    if (splitArr.length > 2) {
      const middleName = splitArr[1];
      return middleName;
    }
  }
  return null;
};

/** Get the driver's last name from the display_name */
const getDriverLastNameFromDisplayName = (displayName = null) => {
  if (displayName) {
    const splitArr = displayName.split(` `);
    if (splitArr.length > 1) {
      const lastName = splitArr[splitArr.length - 1];
      return lastName;
    }
  }
  return null;
};

/** Get driver initials string from an object */
const getDriverInitials = (obj = null) => {
  // Default fallback initials string when not enough info is provided
  const fallbackInitials = `N/A`;

  // Check for obj
  if (obj) {
    // Set local variables
    const fullName = getDriverName(obj) || fallbackInitials;

    // Get/Set initials
    if (fullName !== fallbackInitials) {
      // Split the full name into first, middle and last
      const firstName = getDriverFirstNameFromDisplayName(fullName);
      const middleName = getDriverMiddleNameFromDisplayName(fullName);
      const lastName = getDriverLastNameFromDisplayName(fullName);

      // Assign each initial
      const firstI = firstName ? firstName[0] : ``;
      const middleI = middleName ? middleName[0] : ``;
      const lastI = lastName ? lastName[0] : ``;

      // Return built initials
      const initials = `${firstI}${middleI}${lastI}`.toUpperCase();
      if (initials && initials !== ``) return initials;
    }
  }
  return fallbackInitials;
};

////////// COMPONENT //////////
export default function ConciergeDetailsInfo(props) {
  const cls = useStyles();
  const { concierge, loaner, lyft, mainImageUrl } = props;

  // Get title & vehicle name based on if using a lyft, loaner, or consumer move
  const getVehicleTxt = () => {
    if (loaner) return `Loaner\xa0Vehicle:`;
    else return `Arrival\xa0Vehicle:`;
  };

  // Get the vehicle image & tooltip text based on move status
  const getVehicleImageTxt = () => {
    if (concierge) return getFormattedVehicleFromMove(concierge);
    else if (loaner) return getFormattedVehicleFromMove(loaner);
    else if (lyft && lyft.driver_vehicle_img_url) return getFormattedVehicleFromMove(lyft);
    else return `No Vehicle Info`;
  };
  const getVehicleImage = () => {
    if (mainImageUrl) return mainImageUrl;
    else if (lyft && lyft.driver_vehicle_img_url) return lyft.driver_vehicle_img_url;
    else return placeholder;
  };

  // Get the displayed status based on the loaner move on consumer move
  const getStatus = () => {
    if (concierge.cancel_status) {
      if (concierge.cancel_status === `canceled`) return `Canceled`;
      else if (concierge.cancel_status === `pending`) return `Cancel Pending`;
      else if (concierge.cancel_status === `seen`) return `Cancel Seen`;
      else if (concierge.cancel_status === `started`) return `Cancel Started`;
      else if (concierge.cancel_status === `delivered`) return `Cancel Delivered`;
      else return `Waiting`;
    } else if (loaner && loaner.status && loaner.sequence < concierge.sequence) {
      if (
        (loaner.status === `pickup arrived` || loaner.status === `pickup successful`) &&
        (!concierge.status || concierge.status === `dispatched`)
      )
        return `Driver is picking up loaner vehicle`;
      else if (loaner.status === `delivery started` && (!concierge.status || concierge.status === `dispatched`))
        return `Driver is en route to your location`;
      else if (loaner.status === `delivery arrived` && (!concierge.status || concierge.status === `dispatched`))
        return `Driver has arrived at your location`;
      else if (
        loaner.status === `delivery successful` &&
        (!concierge.status || concierge.status === `dispatched` || concierge.status.includes(`pickup`))
      )
        return `Driver is picking up your vehicle`;
      else if (concierge.status === `delivery started`) return `Vehicle is en route to service location`;
      else if (concierge.status === `delivery arrived`) return `Vehicle has arrived at service location`;
      else if (concierge.status === `delivery successful`) return `Vehicle has been delivered successfully`;
      else return `Waiting`;
    } else if (loaner && loaner.status && loaner.sequence > concierge.sequence) {
      if (
        (loaner.status === `pickup arrived` || loaner.status === `pickup successful`) &&
        (!concierge.status || concierge.status === `dispatched`)
      )
        return `Driver is picking up loaner vehicle`;
      else if (loaner.status === `delivery started` && (!concierge.status || concierge.status === `dispatched`))
        return `Driver is en route to your location`;
      else if (loaner.status === `delivery arrived` && (!concierge.status || concierge.status === `dispatched`))
        return `Driver has arrived at your location`;
      else if (
        loaner.status === `delivery successful` &&
        (!concierge.status || concierge.status === `dispatched` || concierge.status.includes(`pickup`))
      )
        return `Driver is picking up your vehicle`;
      else if (concierge.status === `delivery started`) return `Vehicle is en route to service location`;
      else if (concierge.status === `delivery arrived`) return `Vehicle has arrived at service location`;
      else if (concierge.status === `delivery successful`) return `Vehicle has been delivered successfully`;
      else return `Waiting`;
    } else if (concierge.status) {
      if (concierge.status === `pickup started`)
        return concierge.consumer_at_pickup
          ? `Driver is en route to your location`
          : `Driver is en route to service location`;
      else if (concierge.status === `pickup arrived`)
        return concierge.consumer_at_pickup
          ? `Driver has arrived at your location`
          : `Driver has arrived at service location`;
      else if (concierge.status === `pickup successful`)
        return concierge.consumer_type === 'customer'
          ? `Driver is picking up your vehicle`
          : `Driver is picking up loaner vehicle`;
      else if (concierge.status === `delivery started`)
        return concierge.consumer_at_pickup
          ? `Vehicle is en route to service location`
          : `Vehicle is en route to your location`;
      else if (concierge.status === `delivery arrived`)
        return concierge.consumer_at_pickup
          ? `Vehicle has arrived at service location`
          : `Vehicle has arrived at your location`;
      else if (concierge.status === `delivery successful`) return `Vehicle has been delivered successfully`;
      else return `Waiting`;
    } else return `Waiting`;
  };

  // Get title & expected time based on the move status
  const getTimeTxt = () => {
    if (concierge.status && concierge.status.includes(`delivery`) && concierge.delivery_time)
      return `Expected\xa0Delivery\xa0Time:`;
    else if (concierge.pickup_time) return `Expected\xa0Pickup\xa0Time:`;
    else return `Start\xa0Time`;
  };
  const getTime = () => {
    if (concierge.status && concierge.status.includes(`delivery`) && concierge.delivery_time)
      return moment(concierge.delivery_time).format(`LT`);
    else if (concierge.pickup_time) return moment(concierge.pickup_time).format(`LT`);
    else if (loaner) return moment(loaner.pickup_started || null).format(`LT`);
    else return moment(concierge.pickup_started || null).format(`LT`);
  };

  return (
    <>
      <div className={cls.main}>
        <div className={cls.info}>
          <Typography className={cls.infoTxt}>VEHICLE</Typography>
          <div className={cls.smallBreak} />
          <Tooltip title={getVehicleImageTxt()}>
            <img className={cls.infoImage} src={getVehicleImage()} alt='vehicle' />
          </Tooltip>
        </div>

        <div className={cls.paper}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography className={cls.headTxt} style={{ textAlign: 'left' }}>
                Information
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={cls.headTxt} style={{ textAlign: 'center' }}>
                {moment(concierge.pickup_time || ``).format(`MM/DD/YYYY`)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={cls.headTxt} style={{ textAlign: 'right' }}>
                #{concierge.id}
              </Typography>
            </Grid>
          </Grid>

          <div className={cls.break} />

          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Your&nbsp;Vehicle:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{getFormattedVehicleFromMove(concierge)}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>{getVehicleTxt()}&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {loaner ? getFormattedVehicleFromMove(loaner) : getFormattedVehicleFromMove(lyft)}
                </Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>{getTimeTxt()}&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{getTime()}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Current&nbsp;Status:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{getStatus()}</Typography>
              </div>
            </Grid>

            <div className={cls.hiddenLineBreak} />

            <Grid item sm={6} xs={12}>
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Driver&nbsp;Name:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {getDriverFirstNameFromDisplayName(getDriverName(concierge))}
                </Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Service&nbsp;Location:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{concierge.customer.name || `Unknown`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Your&nbsp;Name:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>{concierge.consumer_name || `-`}</Typography>
              </div>
              <div className={cls.lineBreak} />
              <div className={cls.listItem}>
                <Typography className={cls.keyTxt}>Your&nbsp;Phone&nbsp;#:&nbsp;&nbsp;&nbsp;</Typography>
                <Typography className={cls.valTxt}>
                  {getCleansedPhoneNumber(concierge.consumer_phone) || `-`}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={cls.info}>
          <Typography className={cls.infoTxt}>DRIVER</Typography>
          <div className={cls.smallBreak} />
          <Tooltip title={getDriverFirstNameFromDisplayName(getDriverName(concierge))}>
            {getDriverAvatar(concierge) ? (
              <img className={cls.infoImage} src={getDriverAvatar(concierge)} alt='driver' />
            ) : (
              <div className={cls.infoImage}>
                <Typography className={cls.infoNoDriverTxt}>{getDriverInitials(concierge)}</Typography>
              </div>
            )}
          </Tooltip>
        </div>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  info: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  infoTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  infoImage: {
    objectFit: 'cover',
    display: 'block',
    position: 'relative',
    width: '128px',
    height: '128px',
    borderRadius: '50%',
    background: `#cdd6e5`,
    verticalAlign: 'top',
    boxShadow: theme.shadow.medium,
  },
  infoNoVehicleTxt: {
    position: 'absolute',
    top: '42%',
    left: 0,
    right: 0,
    color: theme.palette.common.white,
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 700,
  },
  infoNoDriverTxt: {
    position: 'absolute',
    top: '21%',
    left: 0,
    right: 0,
    color: theme.palette.common.white,
    textAlign: 'center',
    fontSize: 48,
    fontWeight: 700,
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: `default`,
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  btn: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: theme.palette.divider,
  },
  hiddenLineBreak: {
    display: 'none',
    width: '100%',
    height: '1px',
    marginTop: '-2px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: theme.palette.divider,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));
