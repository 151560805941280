import React, { useContext } from 'react';
import { makeStyles, AppBar } from '@material-ui/core';

import { GlobalContext } from '../global-context';

////////// COMPONENT //////////
export default function Nav() {
  const cls = useStyles();
  const ctx = useContext(GlobalContext);

  return (
    <>
      {ctx.theme && (
        <div className={cls.nav}>
          <AppBar className={cls.bar}>
            <div className={cls.logoBox}>
              {ctx.logo ? <img className={cls.logoImg} alt={`logo`} src={ctx.logo} /> : null}
            </div>
          </AppBar>
        </div>
      )}
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  nav: {
    display: 'block',
    position: 'relative',
    width: '100%',
    height: 64,
  },
  bar: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0,
    width: '100%',
    height: 64,
    background: theme.palette.backdrop,
    boxShadow: theme.shadow ? theme.shadow.nav : 'none',
  },
  logoBox: {
    minHeight: 40,
    maxHeight: 40,
  },
  logoImg: {
    minHeight: 40,
    maxHeight: 40,
  },
}));
