export function useVehiclePhotos() {
  /** Sort images by a specified order */
  const sortImages = images => {
    if (images && images.length) {
      const imageOrder = [
        `driver-front`,
        `driver-front-wheel`,
        `driver-rear`,
        `driver-rear-wheel`,
        `passenger-front`,
        `passenger-front-wheel`,
        `passenger-rear`,
        `passenger-rear-wheel`,
        `instrument-cluster`,
        `interior-windshield`,
        `interior-front`,
        `interior-rear`,
        `exterior-roof`,
        `interior-trunk`,
        `interior-vin`,
        `vehicle-vin`, // This is the same as interior-vin, but for older moves
        `vehicle-weight-badge`,
        `fuel-pump`,
      ];

      images = images.map((image, i) => {
        let num = 100;
        if (image.step_id === `damages`) num = 500;
        if (image.step_id === `signature`) num = 1000;
        if (imageOrder.includes(image.step_id)) num = imageOrder.indexOf(image.step_id);
        return { ...image, order: num };
      });

      return images.sort((a, b) => {
        const aNum = a.order;
        const bNum = b.order;
        if (aNum > bNum) return 1;
        if (aNum < bNum) return -1;
        return 0;
      });
    }
    return [];
  };

  /** Recursively find and build an array of private workflow steps */
  const findPrivateSteps = (privateSteps, stepsToSearch) => {
    if (!stepsToSearch || !stepsToSearch.length) return privateSteps;
    for (let i = 0; i < stepsToSearch.length; i++) {
      const currentStep = stepsToSearch[i];
      if (currentStep.config && currentStep.config.private) privateSteps.push(currentStep);
      if (currentStep.steps && currentStep.steps.length) {
        privateSteps = findPrivateSteps(privateSteps, currentStep.steps);
      }
    }
    return privateSteps;
  };

  /** Generate a list of private step ids */
  const getPrivateStepIds = (workflowset = null) => {
    if (!workflowset) return [];

    let privateSteps = [];

    // Dig through pickup workflow
    if (workflowset.pickupWorkflow && workflowset.pickupWorkflow.steps && workflowset.pickupWorkflow.steps.length) {
      privateSteps = findPrivateSteps(privateSteps, workflowset.pickupWorkflow.steps);
    }

    // Dig through delivery workflow
    if (
      workflowset.deliveryWorkflow &&
      workflowset.deliveryWorkflow.steps &&
      workflowset.deliveryWorkflow.steps.length
    ) {
      privateSteps = findPrivateSteps(privateSteps, workflowset.deliveryWorkflow.steps);
    }

    // Dig through fuel workflow
    if (workflowset.fuelWorkflow && workflowset.fuelWorkflow.steps && workflowset.fuelWorkflow.steps.length) {
      privateSteps = findPrivateSteps(privateSteps, workflowset.fuelWorkflow.steps);
    }

    // Once we have all the private steps, return an array of their ids
    return privateSteps.map(step => step.id);
  };

  /** Convert a step id string to something thats human readable */
  const getHumanReadableLabel = str => {
    str = str.replace(/[.\s_-]/g, ` `).split(' ');

    for (var i = 0, x = str.length; i < x; i++) {
      str[i] = str[i][0].toUpperCase() + str[i].substr(1);
    }
    return str.join(' ');
  };

  /** Get the description of a photo */
  const getPhotoDescription = ({ move = {}, type = `pickup`, signer = null, photo = null }) => {
    try {
      if (photo) {
        if (photo.step_id === `signature`) return `Signer Name: ${signer}`;

        if (photo.step_id === `damages`) {
          const damageNoteProp = photo.name.replace(`damages-`, `damages-note-`);
          let damageNote = ``;
          if (type === `pickup` && move && move.pickup_workflow_data) {
            damageNote = move.pickup_workflow_data[damageNoteProp];
          } else if (type === 'delivery' && move && move.delivery_workflow_data) {
            damageNote = move.delivery_workflow_data[damageNoteProp];
          }
          return damageNote ? `Damage: ${damageNote}` : `Damage`;
        }
      }
    } catch (err) {
      console.log('Error getting photo description', err.message);
    }
    return null;
  };

  /** Get the carousel images array */
  const getCarouselImagesArray = ({ move = {}, type = `pickup`, signer = null }) => {
    const vehiclePhotosArray = type === `pickup` ? move.pickup_photos : move.delivery_photos;
    const sortedPhotosArray = sortImages(vehiclePhotosArray);
    const privateStepIds = getPrivateStepIds(move.workflowset);
    const filteredPhotosArray = sortedPhotosArray.filter(p => !privateStepIds.includes(p.step_id));

    let carouselImagesArray = [];

    for (const index in filteredPhotosArray) {
      if (Object.hasOwnProperty.call(filteredPhotosArray, index)) {
        const photo = filteredPhotosArray[index];
        if (!photo) continue;
        if (!photo.step_id) continue;

        let label = getHumanReadableLabel(photo.step_id === `damages` ? photo.name : photo.step_id);
        let description = getPhotoDescription({ move, type, signer, photo });

        let carouselPhotoObj = {
          id: photo.step_id,
          label,
          original: photo.url,
          originalTitle: label,
          thumbnail: photo.url,
          thumbnailTitle: label,
          thumbnailLabel: label,
          description: description || label,
          isSignature: photo.step_id === 'signature',
          index,
        };

        carouselImagesArray.push(carouselPhotoObj);
      }
    }

    return carouselImagesArray;
  };

  return { getCarouselImagesArray, sortImages };
}
