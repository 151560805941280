import React from 'react';
import { matchPath } from 'react-router';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ApolloClient from 'apollo-client';
import { WebSocketLink } from 'apollo-link-ws';
import { HttpLink } from 'apollo-link-http';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { GlobalContext } from './global-context';
import NotificationPopup from './reusable/NotificationPopup';
import { withWidth, CssBaseline } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import axios from 'axios';
import sdk from '@hopdrive/sdk';

import { Helmet } from 'react-helmet';

import LandingPage from './components/LandingPage';
import Nav from './components/Nav';
import Content from './components/Content';

let log = false;
var https = require('https');

const version = require('./version.js');

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      logo: '',
      move: {},
      name: '',
      notificationShow: false,
      notificationVariant: '',
      notificationMessage: '',
      handleNotifications: (show, variant = this.state.notificationVariant, message = this.state.notificationMessage) =>
        this.setState({
          notificationShow: show,
          notificationVariant: variant,
          notificationMessage: message,
        }),
      theme: null,
      width: props.width,
      apolloClient: null,
      setupApollo: token => {
        const authLink = setContext(async () => {
            const clientName = `concierge-portal-${version.tag || 'vX.X.X'}`;
            return {
              headers: {
                authorization: `Bearer ${token}`,
                'hasura-client-name': clientName,
              },
            };
          }),
          wsurl = process.env.REACT_APP_GRAPHQL_WSS_URL,
          httpurl = process.env.REACT_APP_GRAPHQL_URL,
          wsLink = new WebSocketLink({
            uri: wsurl,
            options: {
              lazy: true,
              reconnect: true,
              timeout: 30000,
              connectionParams: async () => {
                const clientName = `concierge-portal-${version.tag || 'vX.X.X'}`;
                return {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    'hasura-client-name': clientName,
                  },
                };
              },
            },
          }),
          httpLink = new HttpLink({
            uri: httpurl,
          }),
          link = split(
            // split based on operation type
            ({ query }) => {
              const { kind, operation } = getMainDefinition(query);
              return kind === 'OperationDefinition' && operation === 'subscription';
            },
            wsLink,
            authLink.concat(httpLink)
          ),
          client = new ApolloClient({
            link,
            cache: new InMemoryCache(),
          });
        log && console.log('Apollo Client Initialized! ', client);

        this.setState({ apolloClient: client });
      },
    };
  }

  componentDidMount = async () => {
    const paths = matchPath(window.location.pathname, { path: '/:id' });
    if (paths) this.state.id = paths.params.id;
    try {
      await axios
        .post(`https://${process.env.REACT_APP_A0_SD}.auth0.com/oauth/token`, {
          grant_type: 'http://auth0.com/oauth/grant-type/password-realm',
          username: this.state.id,
          password: this.state.id,
          client_id: process.env.REACT_APP_A0_ID,
          realm: process.env.REACT_APP_A0_REALM,
        })
        .then(async res => {
          await this.state.setupApollo(res.data.id_token);
          try {
            const agent = new https.Agent({ rejectUnauthorized: false });
            await axios({
              url: `https://${process.env.REACT_APP_GQL_SD}.socialautotransport.com/v1/graphql`,
              method: 'post',
              data: { query: GET_CONCIERGE_MOVE },
              headers: { authorization: `Bearer ${res.data.id_token}` },
              httpsAgent: agent,
            }).then(async res => {
              await this.setState({ move: res.data });
              await this.getTheme();
            });
          } catch (err) {
            console.error(`Unable to retreive move:`, err);
            await this.getTheme();
          }
        });
    } catch (err) {
      console.log('ERROR:', err);
      await this.getTheme();
    }
  };

  getTheme = async () => {
    let customer;
    let manifest;
    let iconObj;
    let move = this.state.move;
    let branding = {};

    if (
      move &&
      Object.keys(move).length > 0 &&
      move.data &&
      move.data.moves &&
      move.data.moves.length > 0 &&
      move.data.moves[0].customer
    ) {
      customer = this.state.move.data.moves[0].customer;
    } else {
      customer = null;
    }

    try {
      let customerConfig = customer && customer.config ? customer.config : {};
      let orgConfig = customer && customer.organization && customer.organization.config ? customer.organization.config : {};
      const enrichedConfig = sdk.configs.enrichCustomerConfig(customerConfig, orgConfig);
      branding = enrichedConfig && enrichedConfig.branding ? enrichedConfig.branding : {};
    } catch (err) {
      console.error(`Failed to build branded theme:`, err);
    }

    console.log('branding', branding)
    let brandName = branding && branding.name ? branding.name : 'HopDrive';
    let logoURL =
      branding && branding.logo_url ? branding.logo_url :
      `https://images.ctfassets.net/0z0lj2yhiguv/2lTLv2BiNOsblm5o04I0nu/bab404e1a1e26ec3a96ae157e5db1dce/logo-banner-light.svg`;
    let primary = branding && branding.primary_color ?  branding.primary_color : `#f44232`;
    let secondary = branding && branding.secondary_color ? branding.secondary_color : `#486496`;
    let backdrop = branding && branding.backdrop_color ? branding.backdrop_color : `#323640`;

    if (brandName !== 'HopDrive') iconObj = { src: logoURL, type: 'url' };
    else iconObj = { src: 'favicon.ico', sizes: '64x64 32x32 24x24 16x16', type: 'image/x-icon' };

    this.setState({
      logo: logoURL,
      name: brandName,
    });

    manifest = {
      ...manifest,
      short_name: 'CP',
      icons: [iconObj],
      name: `Concierge Portal`,
      start_url: '.',
      display: 'standalone',
      theme_color: '#000000',
      background_color: '#ffffff',
    };

    this.setState({
      theme: createMuiTheme({
        palette: {
          primary: {
            main: primary,
            light: `#646c80`,
            dark: `#16181c`,
            contrastText: `#ffffff`,
          },
          secondary: {
            main: secondary,
            light: `#7696c0`,
            dark: `#2b3d59`,
            contrastText: `#ffffff`,
          },
          info: {
            main: `#2080ff`,
            light: `#64b5f6`,
            dark: `#1976d2`,
            contrastText: `#ffffff`,
          },
          error: {
            main: `#ff2050`,
            light: `#ffa0a8`,
            dark: `#d41025`,
            contrastText: `#ffffff`,
          },
          warning: {
            main: `#ffa040`,
            light: `#ffb74d`,
            dark: `#f57c00`,
            contrastText: `#ffffff`,
          },
          success: {
            main: `#20c820`,
            light: `#81c784`,
            dark: `#388e3c`,
            contrastText: `#ffffff`,
          },

          backdrop: backdrop,

          text: {
            primary: `#323640`,
            secondary: `#323640a0`,
            disabled: `#32364072`,
            hint: `#32364072`,
            contrast: `#fff`,
          },

          background: {
            paper: `#ffffff`,
            default: `#fafafa`,
            light: `#f8f8f8`,
            main: `#f4f4f4`,
            dark: `#f0f0f0`,
          },

          action: {
            hover: `#00000010`,
            selected: `#00000020`,
            focus: `#00000030`,
            active: `#00000080`,
            disabled: `#00000060`,
            disabledBackground: `#00000030`,
          },

          divider: `#00000016`,
          drop: `#32364010`,

          fade: [
            `#00000010`,
            `#00000020`,
            `#00000030`,
            `#00000040`,
            `#00000050`,
            `#00000060`,
            `#00000080`,
            `#000000a0`,
            `#000000b4`,
            `#000000c8`,
          ],

          common: {
            black: `#000`,
            white: `#fff`,
          },
        },

        border: [`1px solid #00000020`, `1px solid #00000040`, `1px solid #00000060`],

        shape: {
          borderRadius: `4px`,
          paperRadius: `8px`,
        },

        shadow: {
          none: `none`,
          soft: `0 0 16px #00000016`,
          medium: `0 0 16px #00000024`,
          harsh: `0 0 16px #00000040`,
          nav: `0 0 16px #00000040`,
        },

        typography: { useNextVariants: true },
      }),
    });

    // SET MANIFEST && META TAGS -------------------------------------------------------------------------------------
    document.querySelector('#og_title').setAttribute('content', this.state.name);
    document.querySelector('#og_image').setAttribute('content', this.state.logo);
    log && console.log('Manifest', manifest);
  };

  render() {
    return (
      <>
        <div className='App'>
          <Helmet>
            <meta name='description' id='description' content={`Concierge Portal | ${this.state.name}`} />
            <link rel='icon' id='shortcut_icon' href={this.state.logo} type='image/png' size='16x16' />
            <title id='meta_title'>Concierge Portal | {this.state.name}</title>
          </Helmet>
        </div>

        <MuiThemeProvider theme={this.state.theme}>
          <GlobalContext.Provider value={this.state}>
            <CssBaseline />

            <NotificationPopup
              show={this.state.notificationShow}
              variant={this.state.notificationVariant}
              message={this.state.notificationMessage}
              handleClose={this.state.handleNotifications}
            />

            <Nav />

            <Content>
              <Router>
                {/* Create a component that will handle calling out to Netlify function on mount and then render a child component with the move details (see LandingPage). The path can be configured to any format such as '/consumer/:id' to correlate to dealer.hopdrive.io/consumer/KQ74, where KQ74 is the hashed move ID */}
                <Route path='/:id/' exact component={LandingPage} />
              </Router>
            </Content>
          </GlobalContext.Provider>
        </MuiThemeProvider>
      </>
    );
  }
}

export default withWidth()(App);

const GET_CONCIERGE_MOVE = `
  query {
    moves(
      where: { _or: [{ consumer_pickup: { _eq: true } }, { _and: [{ consumer_type: { _eq: "customer" } }, { consumer_pickup: { _eq: false } }] }] }
      order_by: { sequence: asc_nulls_last }
    ) {
      id
      customer {
        id
        branded
        config
        organization {
          id
          config
        }
      }
    }
  }
`;
