import React from 'react';
import moment from 'moment';

import { makeStyles, Grid, Typography, Tooltip } from '@material-ui/core';

////////// COMPONENT //////////
export default function ConciergeDetailsLocation({ title, type, concierge, loaner, hide }) {
  const cls = useStyles();

  function getLocation() {
    if (!loaner) {
      // Single concierge move (can be either a customer vehicle or loaner)
      if (type === 'pickup') return concierge.consumer_at_pickup ? concierge.lane.pickup : concierge.lane.delivery;
      else return concierge.consumer_at_pickup ? concierge.lane.delivery : concierge.lane.pickup;
    } else {
      // Concierge + loaner move
      if (concierge.sequence > loaner.sequence)
        return type === 'pickup' ? concierge.lane.pickup : concierge.lane.delivery;
      else return type === 'pickup' ? concierge.lane.delivery : concierge.lane.pickup;
    }
  }

  // Get pickup or delivery time based on which location this is
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // FOR FUTURE REFERENCE: We need to be able to predict the delivery time after the concierge move is picked up //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const getTime = () => {
    let myTime = `N/A`;
    if (!loaner) {
      // Single concierge move (can be either a customer vehicle or loaner)
      if (type === 'pickup' && concierge.consumer_at_pickup)
        myTime = moment(concierge.pickup_arrived || concierge.pickup_time).format(`MM/DD/YYYY LT`);
      else if (type === 'pickup' && !concierge.consumer_at_pickup)
        myTime = moment(concierge.delivery_arrived || concierge.delivery_time).format(`MM/DD/YYYY LT`);
      else if (type === 'delivery' && concierge.consumer_at_pickup)
        myTime = moment(concierge.delivery_arrived || concierge.delivery_time).format(`MM/DD/YYYY LT`);
      else if (type === 'delivery' && !concierge.consumer_at_pickup)
        myTime = moment(concierge.pickup_arrived || concierge.pickup_time).format(`MM/DD/YYYY LT`);
      else myTime = `N/A`;
    } else {
      // Concierge + loaner move (always starts at dealer)
      if (type === 'pickup' && concierge.sequence > loaner.sequence)
        myTime = moment(concierge.pickup_arrived || concierge.pickup_time).format(`MM/DD/YYYY LT`);
      else if (type === 'pickup' && concierge.sequence < loaner.sequence)
        myTime = moment(concierge.delivery_arrived || concierge.delivery_time).format(`MM/DD/YYYY LT`);
      else if (type === 'delivery' && concierge.sequence > loaner.sequence)
        myTime = moment(concierge.delivery_arrived || concierge.delivery_time).format(`MM/DD/YYYY LT`);
      else if (type === 'delivery' && concierge.sequence < loaner.sequence)
        myTime = moment(concierge.pickup_arrived || concierge.pickup_time).format(`MM/DD/YYYY LT`);
      else myTime = `N/A`;
    }

    if (myTime === `Invalid date`) return `N/A`;
    return myTime;
  };

  return (
    <>
      <div className={cls.paper}>
        <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
          <Grid item xs>
            <Typography className={cls.headTxtL}>{title}</Typography>
          </Grid>
          <Grid item>
            <Tooltip title={`Expected arrival time at this location`} placement='top'>
              <Typography className={cls.headTxtR}>{getTime()}</Typography>
            </Tooltip>
          </Grid>
        </Grid>
        <div className={cls.smallBreak} />

        {hide ? (
          <div className={cls.location}>
            <Typography className={cls.nameTxt}>Location Not Provided</Typography>
            <Typography className={cls.addressTxt}>Address unavailable</Typography>
          </div>
        ) : (
          <a href={`https://maps.google.com/?q=${getLocation().address}`} target='_blank' rel='noopener noreferrer'>
            <Tooltip placement='top' title={`Click to see this location on Google Maps`}>
              <div className={cls.location}>
                <Typography className={cls.nameTxt}>{getLocation().name}</Typography>
                <Typography className={cls.addressTxt}>{getLocation().address}</Typography>
              </div>
            </Tooltip>
          </a>
        )}
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.medium,
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
    cursor: 'default',
  },
  location: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#00000008',
    '&:hover': {
      backgroundColor: '#00000016',
    },
    cursor: 'pointer',
    transition: '0.2s',
  },
  nameTxt: {
    lineHeight: 1.25,
    fontSize: 18,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  addressTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
}));
